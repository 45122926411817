import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import {
    Connection,
    PublicKey,
    clusterApiUrl,
} from "@solana/web3.js";
import * as anchor from "@coral-xyz/anchor";
import { convertAmountToLamports, getProvider, normalizeAmount } from "../../contract";
import { useWallet, WalletContextState } from "@solana/wallet-adapter-react";
import tarsStakingIDL from "../../contract/idl/dojo_staking_nft_program.json";
import {
  stakingTarsId,
  STAKING_CONFIG_ACCOUNT,
  TAI_AUTHORITY_ID,
  STAKING_STATS_ACCOUNT,
} from "../../contract/common";
import { DojoStakingNftProgram as TarsStakingNftProgram } from "../../contract/types/dojo_staking_nft_program";
import { getNumberForInputField } from "../../utils";
import { useEffect, useState } from "react";
import { ASSOCIATED_TOKEN_PROGRAM_ID, getAssociatedTokenAddress, TOKEN_PROGRAM_ID,  } from "@solana/spl-token";
import { MultiplierRecord } from "../../contract/accTypes";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

// import { SYSTEM_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/native/system";


const SYSTEM_PROGRAM_ID = new PublicKey("11111111111111111111111111111111");
const mplCoreId = new PublicKey("CoREENxT6tW1HoK8ypY1SxRMZTcVPm7R94rH4PZNhX7d");




// const SOLANA_RPC = clusterApiUrl("devnet")
const SOLANA_RPC = 'https://mainnet.helius-rpc.com/?api-key=571874a6-e07b-4be4-8296-e7329c31cc66'
// const opts = {
//   preflightCommitment: "processed",
// } as any;

const connection = new Connection(SOLANA_RPC, "confirmed");

const stringFields = ["NAME", "URI"];
const disabledButtons = ["END PROGRAM"]
const refecthWithDelay = ["EXIT FEES"]


const ButtonComponent = ({onClick, text, disabled, id, inputVal, setInputVal, titles, fetchValueFromContract}: any) => {
 
  const [value, setValue] = useState("")

  const [tableData, setTableData] = useState()
console.log("tableData", tableData)
  useEffect(()=>{
(async ()=>{
  // fetch updated value from contract
  if(Object.keys(inputVal).length > 1){
    const newValue = await fetchValueFromContract();
console.log("newValue", newValue)
    setTableData(newValue)


  }else{
    const newValue = await fetchValueFromContract()
    setValue(newValue)
  }
  


  // reset all input fields
  Object.keys(inputVal).map((valKey, index) => {

    if(stringFields.includes(titles[index])){
      setInputVal("", valKey, id)
    }else{
      setInputVal("0", valKey, id)
    }

  })
})()
  },[])


  const columns = [
    { id: "multiplier", label: "Multiplier", minWidth: 100 },
    { id: "minimumRewardAge", label: "Minimum Reward Age", minWidth: 100 },
  ];

  return (
    <Box   sx={{
      // border:"1px solid red",
       width:"35%", display:"flex", flexDirection:"row", alignItems:"center" , justifyContent:"space-between", gap:3, padding: 3}} >
        <Button
    variant='contained'
    sx={{
      outline: 'none',
      boxShadow: 'none',
      border: 'none',
      borderRadius: '30px',
      fontSize: '24px',
      textTransform: 'none',
      overflow: 'hidden',
      padding: "10px",
      background:
        'linear-gradient(88.75deg, #1EFB9C -6.85%, #9845FF 106.9%)',
      height: '47px',
      width: '200px',
      '&:hover': {
        opacity: 0.9,
        backgroundColor: '#72AFF2',
      },
      '&:disabled': {
        background: "rgb(51, 51, 51)",
        opacity: 0.5,

        cursor: "not-allowed !important",
      },
    }}    
    onClick={ async () => {
      await onClick()

      // reset all input fields
      Object.keys(inputVal).map((valKey, index) => {

        if(stringFields.includes(titles[index])){
          setInputVal("", valKey, id)
        }else{
          setInputVal("0", valKey, id)
        }

      })

      console.log("refetching with delay ------", refecthWithDelay.includes(titles[0]))

      // fetch updated value from contract
      setTimeout(async ()=>{

        if(Object.keys(inputVal).length > 1){
          const newValue = await fetchValueFromContract();
      console.log("newValue", newValue)
          setTableData(newValue)

        }else{

        const newValue = await fetchValueFromContract()
        setValue(newValue)
      }
      }, refecthWithDelay.includes(titles[0]) ? 4500 : 2500)
    }}
    disabled={disabled || disabledButtons.includes(text) || Object.keys(inputVal).some(valKey => inputVal[valKey].length === 0 || Number(inputVal[valKey]) === 0)}
    // disabledButtons.includes(text) ||
    //  reward ? reward < animationsArrayWithText[currentItem].price : true}

    // disabled={(!publicKey) ? false : true}
    
  >
    <Typography
      fontSize={'16px'}
      fontWeight={600}
      lineHeight={'24px'}
      fontFamily={'Inter'}
      color='rgba(255, 255, 255, 1)'
    >
      {text}
    </Typography>
  </Button>



{
  // @ts-ignore
//   tableData?.rows && tableData?.columns && Object.keys(inputVal).length > 1 && (
// <>
// <div style={{ height: 200, width: '50%' }}>
//       <DataGrid
//       // @ts-ignore
//         rows={tableData?.rows}
//         // @ts-ignore
//         columns={tableData?.columns}
//         hideFooter={true}
//         // initialState={{
//         //   pagination: {
//         //     paginationModel: { page: 0, pageSize: 5 },
//         //   },
//         // }}
//         // pageSizeOptions={[5, 10]}
//         checkboxSelection={false}
//       />
//     </div>
// {/* <Table sx={{
//   border:"1px solid white",
//   width: 350
// }} >
// <TableHead>
//   <TableRow >
//   {columns.map((column) => (
//                 <TableCell
//                   key={column.id}
//                   align={"center"}
//                   style={{ minWidth: column.minWidth }}
//                 >
//                   {column.label}
//                 </TableCell>
//               ))}
//   </TableRow>
// </TableHead>

// <TableBody>
//   {tableData?.length > 0 && tableData?.map((data, index) => (
//     <TableRow>
//       {Object.values(data).map(record => (
//         <TableCell
//                   key={index}
//                   align={"center"}
//                 >
//                   {/* @ts-ignore 
//                   {record}
//                 </TableCell>
//       ))}
      
//     </TableRow>
//   ))
//   }
// </TableBody>
// </Table> */}

// </>
//   )
}



{
  inputVal ? (
    <Box sx={{display:"flex", flexDirection:"column"}} >
    {Object.keys(inputVal).map((valKey, index) => (
<Box>
<Typography
              fontSize={'14px'}
              fontWeight={400}
              lineHeight={'21px'}
              fontFamily={'Inter'}
              color='rgba(255, 255, 255, 0.5)'
            >
              { Object.keys(inputVal).length > 1 ? titles[index] :  (
              <>
              {`${titles[index]} : `} 
              <span
                style={{
                  color: 'rgba(164, 62, 255, 1)',
                  fontFamily: 'Inter',
                  fontSize: "20px"
                }}
              >{value}</span>
              </>
            )
              
              }
            </Typography>
<TextField
              variant='standard'
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: '24px',
                  fontWeight: 400,
                  lineHeight: '36px',
                  fontFamily: 'Inter',
                  color: 'rgba(255, 255, 255, 1)',
                  paddingTop: '6px',
                  paddingLeft:"10px",
                  paddingBottom: '6px',
                  border:"1px solid white"
                },
              }}
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              value={inputVal[valKey]}
              defaultValue={0}
              // disabled={!publicKey ? true : false}
              // onChange={e => setStakeInput(e.target.value)}
              onChange={(e) => {
                console.log("kar length", e.target.value.length);

                let input = e.target.value;

                console.log("kar input", input);

                const regex = /^0+(?!$)/;
                input = input.replace(regex, "");

                console.log('input', input)

                var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
                const formatRes = format.test(input)
                console.log('format res', formatRes)

                
console.log("titles[index]",titles[index], stringFields, titles[index].includes(stringFields))
                if(stringFields.includes(titles[index])){
                  setInputVal(input, valKey, id)
                }else{

                  if(formatRes) {return}

                if (input.length == 0) {
                  setInputVal("0", valKey, id);
                }

                const value = getNumberForInputField(input);
                if(value) setInputVal(value, valKey, id);
              }
              }}
            />
            </Box>
          ))}
          </Box>
  ) : (
    <></>
  )
}
            
          
  
  </Box>
  )
}



const Admin = () => {

const wallet = useWallet();
const {publicKey} = useWallet();


const fetchMinStakeAmount = async (
  wallet: any,
  connection: any,
): Promise<number | string> => {
  console.log("fetchMinStakeAmount function", {
    wallet,
    connection,
  });
  try {

    const provider = await getProvider(wallet);
    console.log("provider is ", provider);
   
    //@ts-ignore
    let program = new anchor.Program(
      tarsStakingIDL as anchor.Idl,
      provider
    ) as anchor.Program<TarsStakingNftProgram>;

    console.log('idddddd', program.programId.toBase58())
    const adminDetails = await program.account.stakingConfiguration.all()
    console.log("adminDetails", Number(adminDetails[0].account.minimumStakeAmount))
    let newStakeAmount = normalizeAmount(Number(adminDetails[0].account.minimumStakeAmount));
    
    return newStakeAmount
  } catch (e) {
    console.log("error in fetchMinStakeAmount", e);

    return 'error'
  }
};


    const changeMinStakeAmount = async (
      wallet: any,
      connection: any,
      amount: any,
    ): Promise<string> => {
      console.log("changeMinStakeAmount function", {
        wallet,
        connection,
        amount,
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
    
        let _amount = convertAmountToLamports(amount.amount1);
        console.log("Number(_amount)", Number(_amount))
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingConfiguration.all()
        console.log("adminDetails", Number(adminDetails[0].account.minimumStakeAmount))

        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa STAKING_CONFIG_ACCOUNT', STAKING_CONFIG_ACCOUNT, STAKING_CONFIG_ACCOUNT.toBase58())

        let ix = await program.methods.changeMinimumStakeAmount(_amount).accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          stakingConfigAccount: STAKING_CONFIG_ACCOUNT
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')
        
        return 'success'
      } catch (e) {
        console.log("error in changeMinStakeAmount", e);

        return 'error'
      }
    };
    


    const fetchExitFees = async (
      wallet: any,
      connection: any,
    ): Promise<number | string> => {
      console.log("fetchExitFees function", {
        wallet,
        connection,
      });
      try {
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingConfiguration.all()
        console.log("adminDetails", Number(adminDetails[0].account.exitFeePercentage))
        const newExitFees = Number(adminDetails[0].account.exitFeePercentage);
        console.log("new exit fees", newExitFees)
        // let newStakeAmount = normalizeAmount(Number(adminDetails[0].account.exitFeePercentage));
        
        return newExitFees
      } catch (e) {
        console.log("error in fetchExitFees", e);
    
        return 'error'
      }
    };


    const changeExitFees = async (
      wallet: any,
      connection: any,
      amount: any,
    ): Promise<string> => {
      console.log("changeExitFees function", {
        wallet,
        connection,
        amount,
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
    
    console.log("Number(_amount)", amount)
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingConfiguration.all()
        console.log("adminDetails", Number(adminDetails[0].account.exitFeePercentage))

        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa STAKING_CONFIG_ACCOUNT', STAKING_CONFIG_ACCOUNT, STAKING_CONFIG_ACCOUNT.toBase58())

        let ix = await program.methods.changeExitFee(Number(amount.amount1)).accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          stakingConfigAccount: STAKING_CONFIG_ACCOUNT
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')
        
        return 'success'
      } catch (e) {
        console.log("error in changeExitFees", e);
        return 'error'
      }
    };



    const fetchAPY = async (
      wallet: any,
      connection: any,
    ): Promise<number | string> => {
      console.log("fetchAPY function", {
        wallet,
        connection,
      });
      try {
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingConfiguration.all()
        const allAPYS = adminDetails[0].account.apyChanges;
        console.log("adminDetails", Number(allAPYS[allAPYS.length-1].newApr))
        const newAPY = Number(allAPYS[allAPYS.length-1].newApr);
        console.log("new APY", newAPY)
        // let newStakeAmount = normalizeAmount(Number(adminDetails[0].account.exitFeePercentage));
        
        return newAPY
      } catch (e) {
        console.log("error in fetchAPY", e);
    
        return 'error'
      }
    };

    const changeAPY = async (
      wallet: any,
      connection: any,
      amount: any,
    ): Promise<string> => {
      console.log("changeAPY function", {
        wallet,
        connection,
        amount,
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
    
    console.log("Number(_amount)", amount)
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingConfiguration.all()
        console.log("adminDetails", adminDetails)
        console.log("adminDetails", Number(adminDetails[0].account.apyChanges[0].newApr))
        if(adminDetails[0].account.apyChanges.length > 0){
          adminDetails[0].account.apyChanges.forEach(apy => {
            console.log("aprChangeTimestamp : ", Number(apy.aprChangeTimestamp), "newApr : ", apy.newApr)
          })
        }


        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa STAKING_CONFIG_ACCOUNT', STAKING_CONFIG_ACCOUNT, STAKING_CONFIG_ACCOUNT.toBase58())

        let ix = await program.methods.changeApr(Number(amount.amount1)).accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          stakingConfigAccount: STAKING_CONFIG_ACCOUNT
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
      
        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')
        
        return 'success'
      } catch (e) {
        console.log("error in changeAPY", e);
        return 'error'
      }
    };


    const endProgram = async (
      wallet: any,
      connection: any,
    ): Promise<string> => {
      console.log("endProgram function", {
        wallet,
        connection,
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingConfiguration.all()
        console.log("adminDetails", adminDetails)
        console.log("adminDetails", Number(adminDetails[0].account.endDate))

        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa STAKING_CONFIG_ACCOUNT', STAKING_CONFIG_ACCOUNT, STAKING_CONFIG_ACCOUNT.toBase58())

        let ix = await program.methods.endProgram().accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          stakingConfigAccount: STAKING_CONFIG_ACCOUNT
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')

        return 'success'
      } catch (e) {
        console.log("error in endProgram", e);
        return 'error'
      }
    };
    

    const fetchMultiplierRecord = async (
      wallet: any,
      connection: any,
    ): Promise<any | string> => {
      console.log("fetchMultiplierRecord function", {
        wallet,
        connection,
      });
      try {
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const multiplierDetails = await program.account.multiplierConfig.all()
        console.log("multiplierDetails------", multiplierDetails)


        
        // [
        //   { field: 'id', headerName: 'ID', width: 70 },
        //   { field: 'firstName', headerName: 'First name', width: 130 },
        //   { field: 'lastName', headerName: 'Last name', width: 130 },
        //   {
        //     field: 'age',
        //     headerName: 'Age',
        //     type: 'number',
        //     width: 90,
        //   },
        // ]
        
        // if(multiplierDetails[0].account.multiplierRecords.length > 0){
        const columns: GridColDef[] = Object.keys(multiplierDetails[0].account.multiplierRecords[0]).map(data => {
          return {
            field: data, headerName: data, width: 200, sortable: false,
          }
        })

        const rows: any = []

        multiplierDetails[0].account.multiplierRecords.forEach((record: any) => {
          let obj = {};

          const allKeys = Object.keys(record);
          const allValues = Object.values(record);

          allKeys.forEach((keys, index) => {
          // @ts-ignore
            obj[keys] = allValues[index]
            // @ts-ignore
            obj.id = keys[index];
          })

          rows.push(obj)

        })

        console.log("rows and column", {rows, columns})
      // }

      
          

        
        // const newMultiplierRecords = multiplierDetails[0].account.multiplierRecords.map((record, index) => {
        //   return {
        //     ...record,
        //   }
        // });



        // let newStakeAmount = normalizeAmount(Number(adminDetails[0].account.exitFeePercentage));
        
        return {rows, columns}
      } catch (e) {
        console.log("error in fetchMultiplierRecord", e);
    
        return 'error'
      }
    };


    const tweakMultiplierRecord = async (
      wallet: any,
      connection: any,
      amount: any,
    ): Promise<string> => {
      console.log("tweakMultiplierRecord function", {
        wallet,
        connection,
        amount,
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
    
    console.log("Number(_amount)", amount)
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingConfiguration.all()
        console.log("adminDetails", adminDetails)

        const multiplierDetails = await program.account.multiplierConfig.all()
        console.log("multiplierDetails------", multiplierDetails)
// const multiplierRecords = multiplierDetails[0].account.multiplierRecords;
    
const multiplierToUpdate = {
  minimumRewardAge: Number(amount.amount2),
  multiplier: Number(amount.amount3)
}
console.log("multiplierToUpdate", multiplierToUpdate, Number(amount.amount1))
        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];

        const MULTIPLIER_ACC_SEED = [Buffer.from("multiplier_acc")];
        const MULTIPLIER_ACC = PublicKey.findProgramAddressSync(
          MULTIPLIER_ACC_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa STAKING_CONFIG_ACCOUNT', STAKING_CONFIG_ACCOUNT, STAKING_CONFIG_ACCOUNT.toBase58())

        let ix = await program.methods.tweakMultiplierRecord(multiplierToUpdate, Number(amount.amount1)).accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          stakingConfigAccount: STAKING_CONFIG_ACCOUNT,
          multiplierAcc: MULTIPLIER_ACC
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        // console.log("simulating ");
        // let logs = await provider.simulate(tx);
        //  console.log("got logs ", logs);
        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')
        
    //     // let tx = new anchor.web3.Transaction();
    //     //.signers(); // incase the payer is not the authority of all the acc passed and an external signature is needed
        return 'success'
      } catch (e) {
        console.log("error in tweakMultiplierRecord", e);
        // console.log(e.toString())
        return 'error'
      }
    };

    const addMultiplierRecord = async (
      wallet: any,
      connection: any,
      amount: any
      // minimumRewardAge: string,
      // multiplier: string
    ): Promise<string> => {
      console.log("addMultiplierRecord function", {
        wallet,
        connection,
        amount
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);

        const multiplierToAdd = {
          minimumRewardAge:  Number(amount.amount1),
          multiplier: Number(amount.amount2)
        }
        console.log("minimumRewardAge -> multiplier", Number(amount.amount1), Number(amount.amount2),multiplierToAdd)
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        // const adminDetails = await program.account.multiplierConfig.all()
        // console.log("adminDetails", adminDetails)

        const multiplierDetails = await program.account.multiplierConfig.all()
        console.log("multiplierDetails", multiplierDetails)
const multiplierRecords = multiplierDetails[0].account.multiplierRecords;
console.log("multiplierRecords", multiplierRecords)

        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];

        const MULTIPLIER_ACC_SEED = [Buffer.from("multiplier_acc")];
        const MULTIPLIER_ACC = PublicKey.findProgramAddressSync(
          MULTIPLIER_ACC_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa MULTIPLIER_ACC', MULTIPLIER_ACC, MULTIPLIER_ACC.toBase58())

        let ix = await program.methods.addMultiplierRecord(multiplierToAdd).accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          multiplierAcc: MULTIPLIER_ACC
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        // console.log("simulating ");
        // let logs = await provider.simulate(tx);
        //  console.log("got logs ", logs);
        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')
        
    //     // let tx = new anchor.web3.Transaction();
    //     //.signers(); // incase the payer is not the authority of all the acc passed and an external signature is needed
        return 'success'
      } catch (e) {
        console.log("error in addMultiplierRecord", e);
        // console.log(e.toString())
        return 'error'
      }
    };


    const removeMultiplierRecord = async (
      wallet: any,
      connection: any,
      amount: any
      // minimumRewardAge: string,
      // multiplier: string
    ): Promise<string> => {
      console.log("removeMultiplierRecord function", {
        wallet,
        connection,
        amount
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);

        const multiplierToAdd = {
          minimumRewardAge:  Number(amount.amount1),
          multiplier: Number(amount.amount2)
        }
        console.log("minimumRewardAge -> multiplier", Number(amount.amount1), Number(amount.amount2),multiplierToAdd)
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        // const adminDetails = await program.account.multiplierConfig.all()
        // console.log("adminDetails", adminDetails)

        const multiplierDetails = await program.account.multiplierConfig.all()
        console.log("multiplierDetails", multiplierDetails)
const multiplierRecords = multiplierDetails[0].account.multiplierRecords;
console.log("multiplierRecords", multiplierRecords)

        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];

        const MULTIPLIER_ACC_SEED = [Buffer.from("multiplier_acc")];
        const MULTIPLIER_ACC = PublicKey.findProgramAddressSync(
          MULTIPLIER_ACC_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa MULTIPLIER_ACC', MULTIPLIER_ACC, MULTIPLIER_ACC.toBase58())

        let ix = await program.methods.removeMultiplierRecord(multiplierToAdd).accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          multiplierAcc: MULTIPLIER_ACC,
          stakingConfigAccount: STAKING_CONFIG_ACCOUNT
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        // console.log("simulating ");
        // let logs = await provider.simulate(tx);
        //  console.log("got logs ", logs);
        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')
        
    //     // let tx = new anchor.web3.Transaction();
    //     //.signers(); // incase the payer is not the authority of all the acc passed and an external signature is needed
        return 'success'
      } catch (e) {
        console.log("error in removeMultiplierRecord", e);
        // console.log(e.toString())
        return 'error'
      }
    };



    const modifyNftTierDetails = async (
      wallet: any,
      connection: any,
      amount: any
    ): Promise<string> => {
      console.log("modifyNftTierDetails function", {
        wallet,
        connection,
        amount
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
    
    
        let __emissionRate = convertAmountToLamports(amount.amount3);
        const tierToUpdate = {
          tierNum: amount.amount1, pointsCost: amount.amount2, emissionRate: __emissionRate
        }
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        // const adminDetails = await program.account.multiplierConfig.all()
        // console.log("adminDetails", adminDetails)

        const multiplierDetails = await program.account.nftTierDetails.all()
        console.log("multiplierDetails", multiplierDetails, multiplierDetails[0].publicKey.toBase58(),multiplierDetails[1].publicKey.toBase58(), multiplierDetails[2].publicKey.toBase58(), multiplierDetails[3].publicKey.toBase58(), multiplierDetails[4].publicKey.toBase58())
        
        if(multiplierDetails.length > 0){
          multiplierDetails.forEach(nft => {
            const tempNFT = {
              [nft.publicKey.toBase58()]:{
              ...nft.account,
              emissionRate: Number(nft.account.emissionRate),
              pointsCost: Number(nft.account.pointsCost)
            }

            }

            console.log("temps  --->  ", nft.account.nftTier, tempNFT)

          })
        }

        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];

        // const NFT_TIER_ACC_SEED = [Buffer.from("nft_tier")];
        const NFT_TIER_ACC_SEED = [Buffer.from("nft_tier"), Buffer.from([Number(amount.amount1)])];
        const NFT_TIER_DETAIL_ACC = PublicKey.findProgramAddressSync(
          NFT_TIER_ACC_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa NFT_TIER_DETAIL_ACC', NFT_TIER_DETAIL_ACC, NFT_TIER_DETAIL_ACC.toBase58())

          const _points = new anchor.BN(amount.amount2);

        let ix = await program.methods.modifyNftTierDetails(Number(amount.amount1), _points, __emissionRate).accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          nftTierDetailAcc: NFT_TIER_DETAIL_ACC
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
     
        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')
        
        return 'success'
      } catch (e) {
        console.log("error in modifyNftTierDetails", e);
        return 'error'
      }
    };



    const createNewNftTier = async (
      wallet: any,
      connection: any,
      amount: any
    ): Promise<string> => {
      console.log("createNewNftTier function", {
        wallet,
        connection,
        amount
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
    
        // let associatedTokenAddress = await getUserTokenAccount(sender);
    
        let __emissionRate = convertAmountToLamports(amount.amount3);
        const tierToUpdate = {
          tierNum: amount.amount1, pointsCost: amount.amount2, emissionRate: __emissionRate
        }
        // console.log("minimumRewardAge -> multiplier", Number(minimumRewardAge), Number(multiplier),multiplierToAdd)
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        // const adminDetails = await program.account.multiplierConfig.all()
        // console.log("adminDetails", adminDetails)

        const multiplierDetails = await program.account.nftTierDetails.all()
        console.log("multiplierDetails", multiplierDetails, multiplierDetails[0].publicKey.toBase58(),multiplierDetails[1].publicKey.toBase58(), multiplierDetails[2].publicKey.toBase58(), multiplierDetails[3].publicKey.toBase58(), multiplierDetails[4].publicKey.toBase58())
        
        if(multiplierDetails.length > 0){
          multiplierDetails.forEach(nft => {
            const tempNFT = {
              [nft.publicKey.toBase58()]:{
              ...nft.account,
              emissionRate: Number(nft.account.emissionRate),
              pointsCost: Number(nft.account.pointsCost)
            }

            }

            console.log("temps  --->  ", nft.account.nftTier, tempNFT)

          })
        }
// const multiplierRecords = multiplierDetails[0].account.;
// console.log("multiplierRecords", multiplierRecords)
        // const adminDetails = await program.account.adminSettings.all()
        // console.log("adminDetails", adminDetails[0].account.adminKey.toBase58())
        // console.log("adminDetails", adminDetails)

        const programId = program.programId;
     
   


        const STAKE_CONFIG_SEED = [Buffer.from("dojo_stake_config")];
        // const STAKING_CONFIG_ACCOUNT = PublicKey.findProgramAddressSync(
        //   STAKE_CONFIG_SEED,
        //   stakingTarsId
        // )[0];

        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];

        // const NFT_TIER_ACC_SEED = [Buffer.from("nft_tier")];
        const NFT_TIER_ACC_SEED = [Buffer.from("nft_tier"), Buffer.from([Number(amount.amount1)])];
        const NFT_TIER_DETAIL_ACC = PublicKey.findProgramAddressSync(
          NFT_TIER_ACC_SEED,
          stakingTarsId
        )[0];

        const NFT_COLLECTION_SEED = [
          Buffer.from("nft_collection"),
          Buffer.from([Number(amount.amount1)]),
        ];

        const COLLECTION_ACCOUNT = PublicKey.findProgramAddressSync(
          NFT_COLLECTION_SEED,
          stakingTarsId
        )[0];
   
        
          console.log('pdaaaa NFT_TIER_DETAIL_ACC', NFT_TIER_DETAIL_ACC, NFT_TIER_DETAIL_ACC.toBase58())

          const _points = new anchor.BN(amount.amount2);
          // const _emissionRate = new anchor.BN(emissionRate);

          console.log("final ye jarha hai", {
            tierNum: Number(amount.amount1), pointsCost: _points, emissionrate: __emissionRate, name: amount.amount4, uri: amount.amount5
          })

        let ix = await program.methods.createNewNftTier(Number(amount.amount1), _points, __emissionRate, amount.amount4, amount.amount5).accountsStrict({
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          payer: wallet.publicKey as PublicKey,
          nftTierDetailAcc: NFT_TIER_DETAIL_ACC,
          systemProgram: SYSTEM_PROGRAM_ID,
          collection: COLLECTION_ACCOUNT,
          dojoAuthorityAcc: TAI_AUTHORITY_ID,
          mplCore: mplCoreId
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        // console.log("simulating ");
        // let logs = await provider.simulate(tx);
        //  console.log("got logs ", logs);
        const signature = await wallet.sendTransaction(tx, connection);
        console.log("sign is ", signature);
        console.log('TXN success')
        
        return 'success'
      } catch (e) {
        console.log("error in createNewNftTier", e);
        // console.log(e.toString())
        return 'error'
      }
    };


    
    const fetchWithdrawExitFees = async (
      wallet: any,
      connection: any,
    ): Promise<number | string> => {
      console.log("fetchWithdrawExitFees function", {
        wallet,
        connection,
      });
      try {
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
       
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingStats.all()
        console.log("adminDetails", adminDetails, Number(adminDetails[0].account.accumulatedExitFees))
        // console.log("adminDetails", Number(allAPYS[allAPYS.length-1].newApr))
        const newWithdrawExitFees = normalizeAmount(Number(adminDetails[0].account.accumulatedExitFees)).toFixed(4);
        console.log("new newWithdrawExitFees", newWithdrawExitFees)
        // let newStakeAmount = normalizeAmount(Number(adminDetails[0].account.exitFeePercentage));
        
        return newWithdrawExitFees
      } catch (e) {
        console.log("error in fetchWithdrawExitFees", e);
    
        return 'error'
      }
    };

    const withdrawExitFees = async (
      wallet: any,
      connection: Connection,
      amount: any
    ): Promise<string> => {
      console.log("withdrawExitFees function", {
        wallet,
        connection,
        amount
      });
      try {
        const sender = wallet.publicKey as PublicKey;
    
        const provider = await getProvider(wallet);
        console.log("provider is ", provider);
    
        // let associatedTokenAddress = await getUserTokenAccount(sender);
    
        let _amount = convertAmountToLamports(amount.amount1);
        
        console.log("_amount ", amount.amount1, Number(_amount ))
        //@ts-ignore
        let program = new anchor.Program(
          tarsStakingIDL as anchor.Idl,
          provider
        ) as anchor.Program<TarsStakingNftProgram>;
    
        console.log('idddddd', program.programId.toBase58())
        const adminDetails = await program.account.stakingStats.all()
        console.log("adminDetails", adminDetails, Number(adminDetails[0].account.accumulatedExitFees))

        const ADMIN_SIGN_SEED = [Buffer.from("admin")];
        const ADMIN_SIGN_ACCOUNT = PublicKey.findProgramAddressSync(
          ADMIN_SIGN_SEED,
          stakingTarsId
        )[0];

        // const ADMIN_EXIT_FEE_SEED = [Buffer.from("admin_exit_fee_receive_token_account")];
        // const ADMIN_EXIT_FEE_ACCOUNT = PublicKey.findProgramAddressSync(
        //   ADMIN_EXIT_FEE_SEED,
        //   stakingTarsId
        // )[0];

        const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
          "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        );
    
        const tokenMintAddress = new PublicKey(
          "3vmfEaTR9M2Pp5JcFNC8c8u6U4eFUBdq6FQjgPpcnfKS"
          // "A9SyC9hNbdYuZ3ugVsKVudUgZKTDh5D11ZbyDGbhkwGG"
        );
    
        let associatedTokenAddress = PublicKey.findProgramAddressSync(
          [
            sender.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            tokenMintAddress.toBuffer(),
          ],
          SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
        )[0];
    
//         console.log("associatedTokenAddress", associatedTokenAddress.toBase58());
//         console.log("arg values", wallet.publicKey.toBase58(), TOKEN_PROGRAM_ID.toBase58(), tokenMintAddress.toBase58())
// console.log("first arg", [wallet.publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), tokenMintAddress.toBuffer()], typeof [wallet.publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), tokenMintAddress.toBuffer()])
//        const ADMIN_EXIT_FEE_ACCOUNT  =  PublicKey.findProgramAddressSync(
//         [wallet.publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), tokenMintAddress.toBuffer()],
//           SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
//       )[0];
// console.log("ADMIN_EXIT_FEE_ACCOUNT", ADMIN_EXIT_FEE_ACCOUNT, ADMIN_EXIT_FEE_ACCOUNT.toBase58())


        let [pda, bump] = PublicKey.findProgramAddressSync(
          [Buffer.from("dojo_stake_vault")],
          stakingTarsId,
        );


        const adminDetailsFor = await program.account.adminSettings.all()
        const adminPublicKey = adminDetailsFor[0].account.adminKey;
console.log("adminPublicKey", adminPublicKey.toBase58());
        // Replace these constants with the actual values
// const adminPublicKey = new PublicKey("AdminPublicKeyHere"); // The public key of the admin
// const dojoTokenMint = new PublicKey("DojoTokenMintAddressHere"); // The mint address of the token


// Derive the associated token account PDA
// const adminTokenAccountPDA = await getAssociatedTokenAddress(
//   tokenMintAddress, // Always use the associated token program ID
//   sender, // The token program ID
//   false, // The mint address of the token
//   TOKEN_PROGRAM_ID, // The owner of the associated token account
//   ASSOCIATED_TOKEN_PROGRAM_ID
// );
// console.log("AdminTokenAccountPDA: ", adminTokenAccountPDA.toString())


let adminTokenAccountPDA = PublicKey.findProgramAddressSync(
  [
    sender.toBuffer(),
    TOKEN_PROGRAM_ID.toBuffer(),
    tokenMintAddress.toBuffer(),
  ],
  ASSOCIATED_TOKEN_PROGRAM_ID
)[0];


console.log("all args", {
  dojoAuthorityAcc: TAI_AUTHORITY_ID.toBase58(),
          dojoProgramVaultAcc: pda.toBase58(),
          tokenProgram: TOKEN_PROGRAM_ID.toBase58(),
          adminExitFeeReceiveTokenAccount: adminTokenAccountPDA.toBase58(),
          adminSign: provider.wallet.publicKey.toBase58(),
          adminAcc:  ADMIN_SIGN_ACCOUNT.toBase58(),
          stakingStatsAccount: STAKING_STATS_ACCOUNT.toBase58()
})
        
        let ix = await program.methods.withdrawExitFees(_amount).accountsStrict({
          dojoAuthorityAcc: TAI_AUTHORITY_ID,
          dojoProgramVaultAcc: pda,
          tokenProgram: TOKEN_PROGRAM_ID,
          adminExitFeeReceiveTokenAccount: adminTokenAccountPDA,
          adminSign: provider.wallet.publicKey,
          adminAcc:  ADMIN_SIGN_ACCOUNT,
          stakingStatsAccount: STAKING_STATS_ACCOUNT
        }).instruction()
    
        console.log("ix", ix);
    
        let tx = new anchor.web3.Transaction();
    
        tx.add(ix);
    
        console.log("tx", tx, wallet);
    
        tx.feePayer = sender;
        tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        // console.log("simulating ");
        // let logs = await provider.simulate(tx);
        //  console.log("got logs ", logs);
        const signature = await wallet.sendTransaction(tx, connection);
        // const signature = await connection.sendTransaction(tx, [wallet.payer], {
        //   skipPreflight: true,
        // })
        console.log("sign is ", signature);
        console.log('TXN success')
        
        return 'success'
      } catch (e) {
        console.log("error in withdrawExitFees", e);
        // console.log(e.toString())
        return 'error'
      }
    };

    const [txnItems, setTxnItems] = useState([
      {
        id: 0,
        name: "CHANGE MIN STAKE AMOUNT",
        method: changeMinStakeAmount,
        getter: fetchMinStakeAmount,
        val0: {amount1:"0"},
        titles: ["MINIMUM STAKE AMOUNT"]
      },
      {
        id: 1,
        name: "CHANGE EXIT FEE PERCENTAGE",
        method: changeExitFees,
        getter: fetchExitFees,
        val1: {amount1:"0"},
        titles: ["EXIT FEE"]
      },
      {
        id: 2,
        name: "CHANGE APY",
        method: changeAPY,
        getter: fetchAPY,
        val2: {amount1:"0"},
        titles: ["APY"]
      },
      {
        id: 3,
        name: "WITHDRAW EXIT FEES",
        method: withdrawExitFees,
        getter: fetchWithdrawExitFees,
        val3: {amount1:"0"},
        titles: ["EXIT FEES"]
      },
      {
        id: 4,
        name: "TWEAK MULTIPLIER RECORD",
        method: tweakMultiplierRecord,
        getter: fetchMultiplierRecord,
        val4: {amount1:"0", amount2:"0", amount3:"0"},
        titles: ["OLD MULTIPLIER", "MINIMUM REWARD AGE", "MULTIPLIER"]
      },
      {
        id: 5,
        name: "ADD MULTIPLIER RECORD",
        method: addMultiplierRecord,
        getter: fetchMultiplierRecord,
        val5: {amount1:"0", amount2:"0"},
        titles: ["MINIMUM REWARD AGE", "MULTIPLIER"]
      },
      {
        id: 6,
        name : "REMOVE MULTIPLIER RECORD",
        method: removeMultiplierRecord,
        getter: fetchMultiplierRecord,
        val6: {amount1:"0", amount2:"0"},
        titles: ["MINIMUM REWARD AGE", "MULTIPLIER"]
      },
      {
        id: 7,
        name: "MODIFY NFT TIER DETAILS",
        method: modifyNftTierDetails,
        getter: () => console.log("abc"),
        val7: {amount1:"0", amount2:"0", amount3:"0"},
        titles: ["TIER NUMBER", "POINTS COST", "EMISSION RATE"]
      },
      {
        id: 8,
        name: "CREATE NEW NFT TIER",
        method: createNewNftTier,
        getter: () => console.log("abc"),
        val8: {amount1: "0", amount2: "0", amount3: "0", amount4: "", amount5: ""},
        titles: ["TIER NUMBER", "POINTS COST", "EMISSION RATE", "NAME", "URI" ]
      },
      {
        id: 9,
        name: "END PROGRAM",
        getter: () => console.log("abc"),
        val9: {},
        method: endProgram,
      },
    ])

    const setValue = (val: string, key: string, id: number) => {
      let tempItems = [...txnItems];
      console.log("before setter", tempItems)
      tempItems[id] = {
        ...tempItems[id],
        [`val${id}`]: {
          //@ts-ignore
          ...tempItems[id][`val${id}`],
          [key]: val
        }
      }

      console.log("after setter", tempItems)

      setTxnItems(tempItems)

    }




// console.log("djhkdjhkej", txnItems)
    return (
        <>
        {/* <button onClick={()=>createMultisig()} >CREATE MULTISIG</button>
        <button onClick={()=>createTransaction()}>CREATE TXN</button> */}
        <br />

          {
           publicKey ?  
            txnItems.map(item => {
              console.log("item---",item)
              return (
              
              <ButtonComponent 
              disabled={false}
              text={item.name} 
              //@ts-ignore
              onClick={()=>item.method(wallet, connection, item[`val${item.id}`])}
              fetchValueFromContract={() => item.getter(wallet, connection)} 
              //@ts-ignore
              id={item.id} inputVal={item[`val${item.id}`]} 
              setInputVal={setValue}
              titles={item?.titles}
               />
            )}) : (
              <div>Please connect your wallet in order to use admin panel!</div>
            )
          }

        </>
    )
}

export default Admin;