import React from "react";
import { Box, Modal, Typography, IconButton, Button } from "@mui/material";
import ModalBody from "./ModalBody";
import { useWallet } from "@solana/wallet-adapter-react";
import Image from "./common/Image";
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const downloadPhantomUrl = "https://chromewebstore.google.com/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa"
const downloadOKXUrl = "https://chromewebstore.google.com/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge"
const downloadTrustWalletUrl = "https://chromewebstore.google.com/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph"

const WALLET_LIST = [
  {
    name: "Phantom",
    logo: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA4IiBoZWlnaHQ9IjEwOCIgdmlld0JveD0iMCAwIDEwOCAxMDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxMDgiIGhlaWdodD0iMTA4IiByeD0iMjYiIGZpbGw9IiNBQjlGRjIiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00Ni41MjY3IDY5LjkyMjlDNDIuMDA1NCA3Ni44NTA5IDM0LjQyOTIgODUuNjE4MiAyNC4zNDggODUuNjE4MkMxOS41ODI0IDg1LjYxODIgMTUgODMuNjU2MyAxNSA3NS4xMzQyQzE1IDUzLjQzMDUgNDQuNjMyNiAxOS44MzI3IDcyLjEyNjggMTkuODMyN0M4Ny43NjggMTkuODMyNyA5NCAzMC42ODQ2IDk0IDQzLjAwNzlDOTQgNTguODI1OCA4My43MzU1IDc2LjkxMjIgNzMuNTMyMSA3Ni45MTIyQzcwLjI5MzkgNzYuOTEyMiA2OC43MDUzIDc1LjEzNDIgNjguNzA1MyA3Mi4zMTRDNjguNzA1MyA3MS41NzgzIDY4LjgyNzUgNzAuNzgxMiA2OS4wNzE5IDY5LjkyMjlDNjUuNTg5MyA3NS44Njk5IDU4Ljg2ODUgODEuMzg3OCA1Mi41NzU0IDgxLjM4NzhDNDcuOTkzIDgxLjM4NzggNDUuNjcxMyA3OC41MDYzIDQ1LjY3MTMgNzQuNDU5OEM0NS42NzEzIDcyLjk4ODQgNDUuOTc2OCA3MS40NTU2IDQ2LjUyNjcgNjkuOTIyOVpNODMuNjc2MSA0Mi41Nzk0QzgzLjY3NjEgNDYuMTcwNCA4MS41NTc1IDQ3Ljk2NTggNzkuMTg3NSA0Ny45NjU4Qzc2Ljc4MTYgNDcuOTY1OCA3NC42OTg5IDQ2LjE3MDQgNzQuNjk4OSA0Mi41Nzk0Qzc0LjY5ODkgMzguOTg4NSA3Ni43ODE2IDM3LjE5MzEgNzkuMTg3NSAzNy4xOTMxQzgxLjU1NzUgMzcuMTkzMSA4My42NzYxIDM4Ljk4ODUgODMuNjc2MSA0Mi41Nzk0Wk03MC4yMTAzIDQyLjU3OTVDNzAuMjEwMyA0Ni4xNzA0IDY4LjA5MTYgNDcuOTY1OCA2NS43MjE2IDQ3Ljk2NThDNjMuMzE1NyA0Ny45NjU4IDYxLjIzMyA0Ni4xNzA0IDYxLjIzMyA0Mi41Nzk1QzYxLjIzMyAzOC45ODg1IDYzLjMxNTcgMzcuMTkzMSA2NS43MjE2IDM3LjE5MzFDNjguMDkxNiAzNy4xOTMxIDcwLjIxMDMgMzguOTg4NSA3MC4yMTAzIDQyLjU3OTVaIiBmaWxsPSIjRkZGREY4Ii8+Cjwvc3ZnPgo=",
    url: downloadPhantomUrl
  },
  {
    name: "OKX Wallet",
    logo: "https://lh3.googleusercontent.com/sO4GPPbgvTCXEFWp-uALYYju9vxVOr5YSr2jqRAclNFSq8FrAIVTQOYmpJV4kMuDM1ZazcgUdjNGLIGKpmMRvR3NzQ=s60",
    url: downloadOKXUrl
  },
  {
    name: "Trust",
    logo: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNjUiIHZpZXdCb3g9IjAgMCA1OCA2NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgOS4zODk0OUwyOC44OTA3IDBWNjUuMDA0MkM4LjI1NDUgNTYuMzM2OSAwIDM5LjcyNDggMCAzMC4zMzUzVjkuMzg5NDlaIiBmaWxsPSIjMDUwMEZGIi8+CjxwYXRoIGQ9Ik01Ny43ODIyIDkuMzg5NDlMMjguODkxNSAwVjY1LjAwNDJDNDkuNTI3NyA1Ni4zMzY5IDU3Ljc4MjIgMzkuNzI0OCA1Ny43ODIyIDMwLjMzNTNWOS4zODk0OVoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8yMjAxXzY5NDIpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMjIwMV82OTQyIiB4MT0iNTEuMzYxNSIgeTE9Ii00LjE1MjkzIiB4Mj0iMjkuNTM4NCIgeTI9IjY0LjUxNDciIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agb2Zmc2V0PSIwLjAyMTEyIiBzdG9wLWNvbG9yPSIjMDAwMEZGIi8+CjxzdG9wIG9mZnNldD0iMC4wNzYyNDIzIiBzdG9wLWNvbG9yPSIjMDA5NEZGIi8+CjxzdG9wIG9mZnNldD0iMC4xNjMwODkiIHN0b3AtY29sb3I9IiM0OEZGOTEiLz4KPHN0b3Agb2Zmc2V0PSIwLjQyMDA0OSIgc3RvcC1jb2xvcj0iIzAwOTRGRiIvPgo8c3RvcCBvZmZzZXQ9IjAuNjgyODg2IiBzdG9wLWNvbG9yPSIjMDAzOEZGIi8+CjxzdG9wIG9mZnNldD0iMC45MDI0NjUiIHN0b3AtY29sb3I9IiMwNTAwRkYiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K",
    url: downloadTrustWalletUrl
  },
]

const WalletModal = ({ open, setOpen }: any) => {
  const { select, wallets, publicKey, disconnect } = useWallet();
  console.log("WalletModal", wallets.filter((wallet) => wallet.readyState === "Installed"));
const installedWalletsNames = wallets.filter((wallet) => wallet.readyState === "Installed").map(wallet => wallet.adapter.name as string); 
const SupportedWalletFiltered = WALLET_LIST.filter(wal => !installedWalletsNames.includes(wal.name as string))
console.log("installedWalletsNames", installedWalletsNames, SupportedWalletFiltered)
  return (
    <Modal
      open={open}
      onClose={(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        reason?: "backdropClick" | "escapeKeyDown"
      ) => {
        if (reason === "backdropClick") {
          e.stopPropagation();
          setOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "#000000",
            opacity: 0.85,
          },
        },
      }}
    >
      <ModalBody
        sx={{
          padding: "12px",
          maxWidth: "468px",

          width: "100%",
          minHeight: "150px",
          borderRadius: "10px",
          border: "0.5px solid rgba(255, 255, 255, 0.16)",
          backgroundColor: "#000",

          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
            <CloseIcon fontSize="medium" sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
       }}
        onClick={() => setOpen(false)}
       />
       <>
        {
        [...wallets.filter((wallet) => wallet.readyState === "Installed"), ...WALLET_LIST.filter(wal => !installedWalletsNames.includes(wal.name)) ].map((wallet: any) => {
          console.log("each wallet", wallet)
          if(wallet?.url){
return (<Button
              key={wallet.name}
              onClick={() => window.open(wallet.url, "_blank")}
              fullWidth
              variant="contained"
              sx={{
                outline: "none",
                boxShadow: "none",
                border: "none",
                borderRadius: "10px",
                fontSize: "24px",
                margin: '5px',
                overflow: "hidden",

                background: "#fff",

                height: "40px",
                width: "180px",

                "&:hover": {
                  opacity: 0.9,
                  //backgroundColor: "#72AFF2",
                  backgroundColor: "#fff",
                },
                "&:disabled": {
                  // backgroundColor: "#72AFF2",
                },
              }}

              // leftIcon={
              //   <Image
              //     src={wallet.adapter.icon}
              //     alt={wallet.adapter.name}

              //     sx={{
              //       height: "24px",
              //       width: "24px",
              //     }}
              //   />
              // }
            >
              <Box sx={{width:"99%",display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}} >
              <Typography
                // fontSize={"18px"}
                // fontWeight={500}
                // lineHeight={"24px"}
                // fontFamily={"Avenir"}
                color="#000"
                // textTransform={"none"}
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'21px'}
                fontFamily={'Inter'}
                marginRight={2}
                // color='rgba(255, 255, 255, 1)'
                textAlign={'center'}
              >
                {wallet.name}
              </Typography>
              <Image
                src={wallet.logo}
                alt={wallet.name}
                sx={{
                  height: "18px",
                  width: "18px",
                }}
              />
              </Box>
            </Button>)
          }else{
            return (<Button
                key={wallet.adapter.name}
                onClick={() => select(wallet.adapter.name)}
                fullWidth
                variant="contained"
                sx={{
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  borderRadius: "10px",
                  fontSize: "24px",
                  margin: '5px',
                  overflow: "hidden",

                  background: "#fff",

                  height: "40px",
                  width: "180px",

                  "&:hover": {
                    opacity: 0.9,
                    //backgroundColor: "#72AFF2",
                    backgroundColor: "#fff",
                  },
                  "&:disabled": {
                    // backgroundColor: "#72AFF2",
                  },
                }}

                // leftIcon={
                //   <Image
                //     src={wallet.adapter.icon}
                //     alt={wallet.adapter.name}

                //     sx={{
                //       height: "24px",
                //       width: "24px",
                //     }}
                //   />
                // }
              >
                <Box sx={{width:"99%",display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}} >
                <Typography
                  // fontSize={"18px"}
                  // fontWeight={500}
                  // lineHeight={"24px"}
                  // fontFamily={"Avenir"}
                  color="#000"
                  // textTransform={"none"}
                  fontSize={'14px'}
                  fontWeight={400}
                  lineHeight={'21px'}
                  fontFamily={'Inter'}
                  marginRight={2}
                  // color='rgba(255, 255, 255, 1)'
                  textAlign={'center'}
                >
                  {wallet.adapter.name}
                </Typography>
                <Image
                  src={wallet.adapter.icon}
                  alt={wallet.adapter.name}
                  sx={{
                    height: "18px",
                    width: "18px",
                  }}
                />
                </Box>
              </Button>)
          }
        })
        }
        </>
        {/* {wallets.filter((wallet) => wallet.readyState === "Installed").length >
        0 ? (
          wallets
            .filter((wallet) => wallet.readyState === "Installed")
            .map((wallet) => (
              <Button
                key={wallet.adapter.name}
                onClick={() => select(wallet.adapter.name)}
                fullWidth
                variant="contained"
                sx={{
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  borderRadius: "10px",
                  fontSize: "24px",
                  margin: '5px',
                  overflow: "hidden",

                  background: "#fff",

                  height: "40px",
                  width: "180px",

                  "&:hover": {
                    opacity: 0.9,
                    //backgroundColor: "#72AFF2",
                    backgroundColor: "#fff",
                  },
                  "&:disabled": {
                    // backgroundColor: "#72AFF2",
                  },
                }}

                // leftIcon={
                //   <Image
                //     src={wallet.adapter.icon}
                //     alt={wallet.adapter.name}

                //     sx={{
                //       height: "24px",
                //       width: "24px",
                //     }}
                //   />
                // }
              >
                <Typography
                  // fontSize={"18px"}
                  // fontWeight={500}
                  // lineHeight={"24px"}
                  // fontFamily={"Avenir"}
                  color="#000"
                  // textTransform={"none"}
                  fontSize={'14px'}
                  fontWeight={400}
                  lineHeight={'21px'}
                  fontFamily={'Inter'}
                  marginRight={2}
                  // color='rgba(255, 255, 255, 1)'
                  textAlign={'center'}
                >
                  {wallet.adapter.name}
                </Typography>
                <Image
                  src={wallet.adapter.icon}
                  alt={wallet.adapter.name}
                  sx={{
                    height: "18px",
                    width: "18px",
                  }}
                />
              </Button>
            ))
        ) : (
          <>
          {/* <Typography>
            No wallet found. Please download a supported Solana wallet
          </Typography> 
          {
            WALLET_LIST.map((wallet)=>(
              <Button
              key={wallet.name}
              onClick={() => window.open(wallet.url, "_blank")}
              fullWidth
              variant="contained"
              sx={{
                outline: "none",
                boxShadow: "none",
                border: "none",
                borderRadius: "10px",
                fontSize: "24px",
                margin: '5px',
                overflow: "hidden",

                background: "#fff",

                height: "40px",
                width: "180px",

                "&:hover": {
                  opacity: 0.9,
                  //backgroundColor: "#72AFF2",
                  backgroundColor: "#fff",
                },
                "&:disabled": {
                  // backgroundColor: "#72AFF2",
                },
              }}

              // leftIcon={
              //   <Image
              //     src={wallet.adapter.icon}
              //     alt={wallet.adapter.name}

              //     sx={{
              //       height: "24px",
              //       width: "24px",
              //     }}
              //   />
              // }
            >
              <Box sx={{width:"99%",display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}} >
              <Typography
                // fontSize={"18px"}
                // fontWeight={500}
                // lineHeight={"24px"}
                // fontFamily={"Avenir"}
                color="#000"
                // textTransform={"none"}
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'21px'}
                fontFamily={'Inter'}
                marginRight={2}
                // color='rgba(255, 255, 255, 1)'
                textAlign={'center'}
              >
                {wallet.name}
              </Typography>
              <Image
                src={wallet.logo}
                alt={wallet.name}
                sx={{
                  height: "18px",
                  width: "18px",
                }}
              />
              </Box>
            </Button>
            ))
          }
          
          </>
        )} */}
      </ModalBody>
    </Modal>
  );
};

export default WalletModal;
